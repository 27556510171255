.linkButtons {
  color: black;
  text-decoration: none;
  font-size: 12px;
}

 .auth-bg .r:nth-child(odd) {
  -webkit-animation: floating 7s infinite;
  animation: floating 7s infinite;
}
 .auth-bg .r:first-child {
  top: -100px;
  right: 150px;
  z-index: 5;
  background: linear-gradient(-135deg,#1de9b6,#1dc4e9);
}
 .auth-bg .r {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
 .auth-bg .r.s:nth-child(2) {
  top: 150px;
  right: -150px;
  background: #04a9f5;
}
 .auth-bg .r:nth-child(2n) {
  -webkit-animation: floating 9s infinite;
  animation: floating 9s infinite;
}
 .auth-bg .r.s {
  width: 20px;
  height: 20px;
}
 .auth-bg .r {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
 .auth-bg .r.s:nth-child(3) {
  left: -150px;
  bottom: 150px;
  background: #1de9b6;
}
 .auth-bg .r:nth-child(odd) {
  -webkit-animation: floating 7s infinite;
  animation: floating 7s infinite;
}
 .auth-bg .r.s {
  width: 20px;
  height: 20px;
}
 .auth-bg .r {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
 .auth-bg .r:nth-child(2n) {
  -webkit-animation: floating 9s infinite;
  animation: floating 9s infinite;
}
 .auth-bg .r:last-child {
  left: 150px;
  bottom: -100px;
  background: linear-gradient(-135deg,#899fd4,#a389d4);
}
 .auth-bg .r {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}