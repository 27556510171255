.drop-down__container{
    display:flex;
    align-items: center;
    margin: .5rem 0;
}
.drop-down__table{
    display:flex;
    align-items: center;
    margin: .5rem 0;
}
.drop-down{
    display: flex;
    background: #fff;
    border-radius: 5px;
    align-items: center;
    height: 2rem;
    width: 100%;
}
.drop-down select{
    background: transparent;
}
.drop-down select:active,
.drop-down select:focus{
    border: none;
    outline: none;
}





























/* .search{
    margin-bottom: .5rem;
    background: #fff;
    display: flex;
    border-radius: 5px;
    width:100%;
} */
/* select {
  padding: .5rem;
  border:0;

  width:auto;
  max-width:400px;
  max-height:1.35rem !important;
} */
/* .search select:active,
.search select:focus{
    border: 0 !important;
    outline: 0 !important;
}
.search button{
    border: none;
    background: transparent;
    outline: none;
    color: #888;
    padding: .5rem .5rem;
}
.search .fa-caret-down{
    color:#FFD162;
    font-size:1.1rem;
}
.add-btn{
    background-color:#FFD162 !important;
    border: 0;
    outline: 0; 
    font-weight: 900;
    padding: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    margin-bottom: .5rem;
} */
