
.employee-paper{
    /*max-width: fit-content !important;/* 
    color: green !important;
    background: white !important; */ 
    width:1400px
}
.invert-dialog{
    color:#FFD162 !important;
    background-color: #000;
}
.invert-dialog *{
    color:#FFD162 !important;
    background-color: #000;
}
.invert-dialog .btn *{
    background-color: transparent !important;
}

.form.employee-container{
    display:flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
}
.form-invert{
    color: green !important;
    background: white !important;
}
.sub-title{
    text-transform:uppercase;
    margin-bottom: 0rem;
}
.employee-id{
    justify-content: space-around !important;
}
.far{
    font-weight: 900;
}
.identity .sub-title{
    margin-bottom: 0rem;
}
.underline{
    border-bottom: 2px solid #000;
    margin-top: -.25rem;
}
.input-control__outer{
    display: flex;
    margin: .5rem;
}
.input-control__inner{
    margin: .2rem;
    display: flex;
    align-items: center;
}
.label{
    margin-right: .5rem;
    min-width: 90px;
    text-align: right;
    text-transform: uppercase;
    font-weight: 500;
}
button{
    outline: none;
    border: 0;
    background: transparent;
    cursor: pointer;
}

.employee.column-container{
    display: flex;
    flex-direction: row;
}

.employee.button-container{
    display: flex;
    flex-direction: column;
}

.employee-container .column1{
    /*flex:0.45;*/
    display:flex;
    flex-direction: column;
    border-right:2px solid #000;
    
}

.employee-container .column2{
    /*flex:0.45;*/
    display:flex;
    /*border-right:2px solid #000;*/
    /*margin: 15px;*/
    
}

.employee-container .avatar{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.column1>div{
    width: 98%;
}

.form input,
.form select,
.form textarea{
    -webkit-font-smoothing: antialiased;
    font: inherit;
    color: currentColor;
    border: 0;
    margin: 0;
    /*display: block;*/
    min-width: 0;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    padding: .5rem .5rem;
    box-shadow: none;
    border-radius: 5px;
    width: 100%;
    /*min-width: 100px; */
}
.form input{
    height: 1.1876em;
    background: #fff !important;
}
.form textarea{
    background: #fff !important;
}
.form input::placeholder{
    text-transform: uppercase;
}
/* .form input:active{
    border: none !important;
    outline: none !important;
} */

input[type='number'] {
    -moz-appearance:textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.employee-container input[type=checkbox]{
    width: 300%;
}

#icon-employee-button-file{
    opacity: 0;
    width: 0;
}

.btn{
    color: #fff;
    font-weight: 400;
    border-radius: 5px;
    background-color: #04a9f5;
    padding: 8px 22px;
    font-size: 0.9375rem;
    width:100%;
}
.btn:hover{
    background-color: #038fcf;
}
.btn .label{
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}
.btn .label .icon{
    display: inherit;
    margin-left: 15px;
    margin-right: -4px;
}
.toggle-details .btn{
    border-top-right-radius: 0;
    border-bottom-right-radius:0 ;
}
.toggle-details .btn:hover,
.toggle-details .btn:active,
.toggle-details .btn.active{
    background:#FFD162 !important ;
    color: #000 !important;
}

.invert{
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    color: #fff !important;
    background-color: #5a6268 !important;
}
.invert .label{
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}
.invert .label .icon{
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;
}
.invert:hover{
    background-color:#62747d !important;
}
.toggle-details .btn{
    margin: .5rem;
    padding: 3px 11px;
}
.btn img{
        -webkit-font-smoothing: antialiased;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-family: Helvetica;
    font-weight: bold;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    margin-left: 10px;
}
.id-label{
    min-width: 110px !important;
}
/* column 1 */

/* column 2 */
.column2{
    display:flex;
    flex-direction: column;
    flex:0.2;
    align-items: center;
}
.avatar{
    display:flex;
    align-items: flex-end;
}
.avatar .img-container{
    width:130px;
    height: 130px;
    border-radius:100%;
}
.avatar img{
    width: 100%;
    height: 100%;
    border-radius:100%;
    /*object-fit: contain;*/
}
/* column 3 only */
.employee-container .column3{
    display: flex;
    flex:1.25;
    flex-direction: column;
}

.buttons{
    display: flex;
    position: static; 
    justify-content: flex-end !important;
    bottom: 0%;
    margin-top: .5rem;
    width:100%;
}
.employee-container .space-between{
    justify-content: space-between;
}
.buttons .btn{
    margin: 0 .5rem;
}
.identity{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.identity h3{
    text-align:right;
    width: 100%;
}
/* employee */
/* workform only */

.gender input{
    min-width: 50px !important;
    max-width: 60px !important;
}

.date input{
    color: rgb(156, 63, 63) !important;
    text-transform: uppercase;
}
.date input{
    max-width:145px !important;
}
input[type="date"]{
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
    /* padding: .5rem;
    padding-left: 0rem; */
}
.date input[type="date" i]{
    padding-inline-start: 0 !important;
}
.causing-overflow{
    margin-top: 1rem !important;
}
.com{
    flex-direction:column;
    margin-top: -1rem !important;
}
.com input{
    min-width:10px !important;
    max-width:50px !important;
}
.com input::placeholder,
.discounts input::placeholder{
    text-align: right;
}
.com .label{
    justify-self: flex-start;
    align-self: flex-start ;
    text-align: left !important;
}

/* work form only */
.workform-container .search-container div{
    display: flex;
    align-items: center;
}
.workform-container .search{
    max-width: 200px !important;
    margin-bottom: .5rem;
    background: #fff;
    display: flex;
    border-radius: 5px;
}
.skills{
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start;
}
.skills div{
    width: fit-content;
}
.skills p{
    text-transform:uppercase;
    font-weight:bold;
    margin: 0;
}
/* contact form only */

.contact{
    width: 100%;
}
.phone-number{
    flex: 1 !important;
}
.contact-code{
    min-width: 30px !important;
    max-width:70px !important;
    margin-right:.5rem !important;
}
/* address form only */
.address-container{
    margin-top:0rem;
    min-width: 400px;
    /* min-height:400px; */
}
.address-container .input-control__inner {
    margin: 0.2rem;
}
.address-container .input-control__inner>div{
    width:100% !important;
}
.address-inputs>div{
    margin: 0 !important;
    display:flex;
    flex-direction: column;
}
.address-input__control{
    flex-direction: column !important;
    margin: 0;
}
.address-input__control>div{
    width:100% !important;
    margin: 0;
}
.address-input__control div{
    margin: 0 !important;
}
.location{
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}
.location img{
    width: 25px;
    height: 25px;
    margin-right: -3.5rem;
}
.street{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    padding: .21rem .5rem;
    width:100%;
}
.street input{
    outline: 0;
    border: 0;
    padding: 0 !important;
}
.street input:active{
    border: 0;
    outline: 0;
}
.street button{
    background: #fff;
    border: 0px;
    outline: 0px;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 0;
}
.int{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 1px solid #FFD162 !important;
}
.ext{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.id-contact{
    margin: 1rem 0;
}

/* Documents form only */
.id-label{
    width:auto;
}
.documents table{
    width:100%;
    border-collapse:collapse;
}
table .uppercase{
    text-transform: uppercase;
}
.documents thead{
    border-bottom: 1px solid black;
}
.theader{
    display: flex;
    justify-content: space-around;
    width:100%;
}
.documents .thead{
    margin-bottom: 1rem;
    display: table-row;
}
.documents th{
    text-transform: uppercase;
    padding: .5rem 0;
}

.documents tbody td{
    background: #fff;
}
.documents td{
    padding: 1rem 0;
}
.documents td:nth-child(2){
    padding: 1rem .2rem;
}
.documents tbody tr:nth-child(even) td {
    background: #FFF7E4;
}
.icons{
    background:#FFD162 !important;
    display: flex;
    height: 100%;
}
.icons img{
    width: 25px;
    height:25px;
}
.icons i{
    margin: 0 .25rem;
    cursor: pointer;
}
.documents .underline{
    margin: .5rem 0;
}

/* warehouse form */
.warehouse-form .comments{
    margin-top: 1rem;
}
.warehouse-form .buttons{
    margin: 1rem 0;
}
.warehouse-form .column2{
    display: none ;
}
.warehouse-form .column1,
.warehouse-form .column3{
    flex:.5;
    
}
.warehouse-form .column3{
    padding: 0;
}
/* customer only */

