/*Generic Rules*/
.module-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.customer-paper{
    max-width: fit-content !important;
}

.customer-container .column1 .add-customer{
    min-width: 580px !important;
}

.customer-container .column1 .update-customer{
    min-width: 580px !important;
    border-right: 2px solid #000;
}

.MuiDialog-paperScrollPaper{
    max-height: calc(100% - 18px ) !important;
}
.customer-container .input-control__inner{
    margin:0.3rem 0 !important;
}
.customer-container .input-control__outer{
    margin:3px ;
} 
/* .customer-container input{
    height: 1.2rem !important;
    padding:0.35rem .5rem !important;
    margin:0;
} */

.customer-container input[type=checkbox]{
    width: 300%;
}
.label-details{
    font-weight: bold;
}
.customer__employee-id .drop-down__container{
    margin:0 !important;
}

.customer-container{
    flex-direction: column;
}
/* column 1 */
.customer-container .column1{
    /*flex:0.45;*/
    width:700px;
    display:flex;
    flex-direction: column;
}

.customer-container .column1.update-customer{
    border-right:2px solid #000;
}

.sub-title h3{
    margin: 0;
    padding: 0;
}
.underline{
    margin: .5rem 0;
}

.price-id{
    display: flex;
    flex-direction: row;
}
.search-add{
    display: flex ;
    flex-direction: row ;
    width: auto !important;
    height: 2.5rem;
}
.credit .label{
    min-width: fit-content !important;
    max-width:auto !important;
    margin: 0rem  0.25rem;
}
.credit-off input{
    min-width: 30px !important;
    background: transparent !important;
    border: 1px solid #000 !important;
}
.discounts .label{
    min-width: 100px;
    max-width:auto !important;
}
.drop-down>div{
    display: flex;
    flex-direction: row;
    height: 2.5rem;
}
.drop-down input{
    min-width: 30px !important;
    max-width: 40px !important;
}
.balance .input-control__inner{
    width:200px !important;
    display: flex;
}
.balance .input-control__inner .search{
    display: flex;
    justify-content: space-around;
}
.balance .label{
    min-width: 90px;
}
.balance select{
    min-width:50px !important;
    max-width:70px !important;
}
.balance .search{
    width: 10px !important;
    min-width: 10px !important;
    max-width: 10px !important;
}
.small-input{
    min-width:50px !important;
    max-width:60px !important;
}

/* column 2 */
.customer-container .column2{
    /*flex:0.45;*/
    display:flex;
    /*border-right:2px solid #000;*/
    /*margin: 15px;*/
    
}
.avatar{
    display:flex;
    align-items: flex-end;
}
.avatar .img-container{
    width:100px;
    height: 100px;
}
.avatar img{
    width: 100%;
    height: 100%;
    /*object-fit: contain;*/
}
/* column 3 only */

.customer-container .column3{
    display: flex;
    flex:1.25;
    flex-direction: column;
    width: 600px;
}

.large-label {
    min-width: 100px;
}
/* workform only */
.skills{
    width: fit-content;
}
.skills p{
    min-width:200px !important;
}
/* contact form only */
.contact-code{
    min-width: 30px !important;
    max-width:70px !important;
    margin-right:.5rem !important;
}
/* address form only */
.address-container{
    min-width: fit-content;
}
.address-inputs>div{
    margin:0.25 0;
    display:flex;
    flex-direction: column;
}
.address-inputs input{
    margin: .1rem 0;
}
.location{
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}
.location img{
    width: 25px;
    height: 25px;
    margin-right: -3.5rem;
}
.street{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    padding: .21rem .5rem;
}
.street input{
    outline: 0;
    border: 0;
    padding: 0 !important;
}
.street input:active{
    border: 0;
    outline: 0;
}
.street button{
    background: #fff;
    border: 0px;
    outline: 0px;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 0;
}

.button-disabled{
    margin:10px 0 0 0;
    cursor:not-allowed;
}

