body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.backdrop-filter-blur{
  background-color: #fff;
  border:  1px solid #dddddd21;
  background-blend-mode: overlay;
  margin-top: 20vh;
}

.auth-bg{
  background-attachment: fixed;
  background-size: cover;
  backdrop-filter: brightness(50%);
  width: 100vw;
  height: 100vh;
  background-image: url('https://i.ibb.co/10vYwR7/bg6.jpg') !important;
}

.MuiTableRow-root .MuiButtonBase-root{
  padding:3px;
}

.MuiOutlinedInput-input {
  padding: 10px 14px !important;
}
/*Estilos modal */
.MuiDialogTitle-root {
  padding: 0px !important;
}
.MuiFormControl-marginNormal {
  margin-top:8px !important;
}
/*Estilos tab*/
.MuiAppBar-colorPrimary {
  color: #333 !important;
}
.PrivateTabIndicator-colorPrimary{
  color: #333 !important;
}
.MuiTab-textColorInherit.Mui-selected {
  background-color: rgba(68,71,70,0.059);
  margin: 0 5px 0 0;
  min-height: 50px;
  color:#007bff;
}
.MuiTab-labelIcon{
  min-height: 50px !important;
}
.MuiTab-labelIcon .MuiTab-wrapper svg{
  position: absolute;
  right: 2px;
  top: 2px;
  font-size: 17px;
}
.PrivateTabIndicator-colorSecondary-39{
  background-color:  #369 !important;
}
